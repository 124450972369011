.MsgBox {
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgb(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  width: auto;
  border: 2px solid var(--themakleur);
  border-bottom: 0px solid black;
  line-height: 1.5;
  font-size: 18px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
}
