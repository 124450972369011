.SideUI button {
  font-size: 16px;
  cursor: pointer;
  border-left: 1px solid rgb(194 194 194);
  width: 140px;
  padding: 4px;
  height: 100%;
  background-color: inherit;
  opacity: 0.8;
}

.SideUI button:last-child {
  border-right: 1px solid rgb(194 194 194);
}

.SideUI button:hover {
  opacity: 1;
  box-shadow: inset 0px 0px 3px rgb(140,140,140);
}

.SideUI button:focus {
  outline: none;
}

.SideUI button .iconFA {
  margin-left: 5px;
  height: 32px;
  display: inline;
  color: var(--themakleur);
}

.SideUI button .textUI {
  display: inline;
  width: 100px;
  text-align: center;
}

.textsmallUI {
  display: none;
}
