.timerWrapper {
  position: initial;
  background-color: white;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Timer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 278px;
  font-size: 65px;
  font-weight: bold;
  color: var(--themakleur);
}

.leftDashboard {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 90px;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  font-size: 22px;
  line-height: 1.3;
  color: var(--themakleur);
}

.rightDashboard {
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: right;
  height: 90px;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  font-size: 22px;
  line-height: 1.3;
  color: var(--themakleur);
}

.rightDashboard .timerspan {
  text-align: left;
  width: 95px;
  display: inline-block;
}

.timerLabel {
  display: inline-block;
  margin-right: 10px;
  /* color: white; */
}

.timerCont {
  display: inline-block;
  color: white;
}

/* @media screen and (max-width: 1100px), screen and (max-height: 700px) {
  .timerWrapper {
    height: 81px;
  }

  .Timer {
    font-size: 45px;
    width: 190px;
  }

  .leftDashboard {
    height: 45px;
    font-size: 18px;
  }
  .rightDashboard {
    height: 45px;
    font-size: 18px;
  }
}

@media (max-width: 850px) {
  .timerWrapper {
    height: 81px;
  }

  .Timer {
    font-size: 45px;
    width: 190px;
  }

  .leftDashboard {
    height: 45px;
    font-size: 18px;
    display: none;
  }
  .rightDashboard {
    height: 45px;
    font-size: 18px;
    display: none;
  }
}

@media (max-width: 600px) {
  .Timer {
    font-size: 27px;
    width: 125px;
    z-index: 0;
  }
  .timerWrapper {
    top: 45px;
  }
} */

#swap-pan-title {
  font-size: 1.2em;
  vertical-align: center;
  margin-top: 12px;
}

#swap-pan-progress > * {
  display: inline-block;
  vertical-align: middle;
}

#progress-outside {
  width: 100px;
  height: 1.2em;
  margin: auto 10px;
}

#progress-inside {
  display: inline-block;
  height: 100%;
  background-color: var(--themakleur);
  border-right: 1px solid darkblue;
  box-sizing: border-box;

  border-left: 1px solid black;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

#progress-inside-empty {
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  display: inline-block;

  border-right: 1px solid black;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

#banner-logo {
  height: 95%;
  margin: 3px 0 0 5px;
}

#swap-pan-progress {
  margin-top: 14px;
}

@media (max-width: 1270px) {
  #swap-pan-title {
    display: none;
  }
}

@media (max-width: 1000px) {
  #swap-pan-progress {
    display: none;
  }
}

@media (max-width: 750px) {
  #banner-logo {
    display: none;
  }
  .SideUI {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .SideUI button {
    width: 100%;
  }
}