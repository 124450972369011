.optDescr {
  display: inline-block;
}

.checkBoxWrapper {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -1px;
}

.optionWrapper {
  width: 500px;
  position: relative;
  margin: 30px 20px 10px 20px;
}

.PopUp .optionWrapper input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.PopUp .optionWrapper label {
  cursor: pointer;
  text-indent: -9999px;
  width: 55px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 55px;
  position: relative;
  display: inline-block;
}

.PopUp .optionWrapper label:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 24px;
  transition: 0.3s;
}

.PopUp input:checked + label {
  background: var(--themakleur);
}

.PopUp input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.PopUp label:active:after {
  width: 30px;
}

@media (max-width: 900px) {
  .optionWrapper {
    width: auto;
  }
  .checkBoxWrapper {
    float: right;
    display: inline-block;
    position: initial;
    right: 0px;
    top: -1px;
  }
  .optDescr {
    display: inline-block;
    max-width: 60%;
  }
}
