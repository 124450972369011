.tip-wrapper {
  .tip {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    transition: 1s;
    z-index: 1;}


  .tipHeader {
    background-color: var(--themakleur);
    color: rgb(0 0 0);
    padding: 5px 15px;
    font-size: 20px;
  }

  .closeTip {
    margin-right: 0px;
    float: right;
    line-height: 17px;
  }

  .closeTip:hover {
    cursor: pointer;
  }

  .hiddenTip {
    transform: translateY(+110%);
  }

  .tipContent {
    background-color: rgb(0, 0, 0, 0.8);
    color: rgb(255 255 255);
    padding: 15px 15px;
    line-height: 1.3;
  }

  @media (max-width: 600px) {
    .tip {
      bottom: 0px;
      right: 0;
      width: 100%;
      font-size: 14px;
    }
    .tipHeader {
      padding: 5px 8px;
      font-size: 16px;
    }
    .tipContent {
      padding: 8px 8px;
    }
    .hiddenTip {
      transform: translateY(+500%);
    }
  }
}