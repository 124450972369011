.PopUp .closeButton {
  position: absolute;
  height: 35px;
  width: 35px;
  right: 10px;
  top: 10px;
  font-size: 25px;
  color: var(--themakleur);
  margin: 0;
  line-height: 25px;
  border-radius: 20px;
  
}

.PopUp .closeButton:hover {
 box-shadow: 0 0 3px 1px grey;
}
