.mc-button {
  margin: 10px auto !important;
  display: block;
  width: 80%;
  font-size: 15pt !important;
  padding: 10px;
  width: 600px;
  text-align: left;
}

.answer-id-text {
  margin-right: 20px;
}
