.PanoramaWrapper {
  position: relative;
  background-color: rgb(29, 29, 29);
  font-family: "Walsheim", sans-serif;
  align-items: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

#PanoramaIframe {
  width: 100%;
  overflow: auto;
  -​webkit-overflow-scrolling: touch;
  transition: opacity 0.6s;
  -webkit-transition: opacity 0.6s;
  opacity: 1;
  pointer-events: auto;
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch; /* Latest specification */
}

.answersheetBtn {
  position: absolute;
  background-color: lightgrey;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 8px;
  font-size: 20px;
  top: 12px;
  left: 12px;
  padding: 12px;
  width: 200px;
}

.answersheetBtn:focus {
  outline: none;
}
