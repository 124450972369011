.PopUp {
  display: none;
  position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255 255 255 / 90%);
    color: #151515;
    padding: 30px;
    max-width: 60%;
    min-width: 200px;
    max-height: 75vh;
    border-radius: 5px;
    line-height: 1.5;
    font-size: 18px;
    overflow-y: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    z-index: 1;
    box-shadow: 0 0 7px 2px #313131;
}

.PopUp h2 {
  color: var(--themakleur);
  font-size: 25px;
  text-align: center;
  margin-bottom: 20px;
  padding: 0px 42px;
}

.small {
  font-size: 15px;
}

.PopUp .popUpBnt {
  font-size: 25px;
  min-width: 100px;
  color: var(--themakleur);
  background-color: white;
  border: 1px solid var(--themakleur);
  margin: 30px 10px 0px 10px;
  border-radius: 5px;
  transition: 0.1s;
}

.PopUp .popUpBnt:hover {
  box-shadow: 0 0 4px 0  grey;
  transition: 0.1s;
}

.PopUp h3 {
  color: var(--themakleur);
  font-size: 20px;
  text-align: left;
  margin: 20px 0px 10px 0px;
  text-align: left;
}

a {
  color: white;
}

@media (max-width: 600px) {
  .PopUp {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    max-width: 95vw;
    max-height: 75vh;
    width: max-content;
    font-size: 16px;
    min-width: 330px;
    z-index: 2;
  }
}
