.login-wrapper {
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#login-swap_first_delivery {
  background: url('./img/firstdeliverybackground.jpg');
  background-size: cover;
}

#login-swap_missing {
  background: url('./img/missingbackground.jpg');
  background-size: cover;
}

#login-swap_drop_off {
  background: url('./img/dropoffbackground.jpg');
  background-size: cover;
}

#login-swap_osr_exchange {
  background: url('./img/osrbackground.jpg');
  background-size: cover;
}

.Login {
  background-color: rgba(255, 255, 255, 0.907);
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  text-align: center;
  width: 600px;
  box-shadow: 0 0 20px 0px #1a1a1a;
  position: relative;

  h2 {
    font-size: 1.5em;
  }
  
  > div {
    height: auto;
  }
  
  .form {
    padding: 1em;
    text-align: left;
  }
  
  label {
    font-weight: 600;
  }

  // for the logo
  img {
    max-width: 200px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .spinner-border {
    vertical-align: middle;
    margin-left: 10px;
  }

  #back-to-overview-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

}