#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

:root {
  --themakleur: #05aae1;
  --whitish: rgb(230, 230, 230);
  --buttongreen: #6aedb0;
}

@font-face {
  font-family: "Walsheim";
  src: local("Walsheim"),
    url(../fonts/GT-Walsheim-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Walsheim";
  font-weight: 700;
  src: local("Walsheim"),
    url(../fonts/GT-Walsheim-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Walsheim";
  font-weight: 900;
  src: local("Walsheim"), url(../fonts/GT-Walsheim-Bold.ttf) format("truetype");
}

.EscapeRoomBody {
  width: 100%;
  display: flex;
  flex: 1;
  min-width: 345px;
}

body {
  background-color: var(--whitish);
  background-position: center;
  color: black;
  font-family: "Walsheim", sans-serif !important;
  font-size: 17px;
  /* min-width: 1000px; */
  /* min-height: 700px; */
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.centertext {
  text-align: center !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b {
  font-weight: bold;
}

.left-align {
  text-align: left;
}

.themakleur {
  color: var(--themakleur);
}

.float-right {
  float: right;
}

.themakleur {
  color: var(--themakleur);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.black {
  color: black;
}
