#homepage-wrapper {
    background-image: url('./img/background.jpg');
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#homepage {
    margin: auto;
    font-family: "Walsheim", sans-serif;
    background-color: rgba(255, 255, 255, 0.907);
    border-radius: 0.5em;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
    text-align: center;
    width: 600px;
    box-shadow: 0 0 20px 0px #1a1a1a;
}

#homepage img {
    margin: auto;
    max-width: 200px;
    margin-top: 10px;
    margin-bottom: 20px;
  
}

#homepage > .card {
    gap: 10px;
    padding: 20px;
    border: none;
    background: none;
    text-align: center;
}

#homepage h1 {
    font-size: 1.5em;
}

#homepage-link-wrapper {
    margin: 15px auto 5px auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: var(--themakleur);
    font-size: 14pt;
}

#homepage-link-wrapper a {
    border: 2px solid var(--themakleur);
    padding: 4px;
    width: 250px;
    border-radius: 4px;
}

#homepage-link-wrapper a:hover {
    color: var(--themakleur);
    background-color: white;
}

#to-manager-dashboard {
    margin: 30px auto 5px auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: grey;
    font-size: 11pt;
}

#to-manager-dashboard a:hover {
    color: grey;
    background-color: white;
    opacity: 0.8;
}


#to-manager-dashboard a {
    border: 2px solid grey;
    padding: 4px;
    width: 250px;
    border-radius: 4px;
}