.login-wrapper {
  margin: auto;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#dashboard-login {
  background: url('../../components/Login/img/firstdeliverybackground.jpg');
  background-size: cover;
}

.Login {
  background-color: rgba(255, 255, 255, 0.907);
  border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  text-align: center;
  width: 600px;
  box-shadow: 0 0 20px 0px #1a1a1a;
  position: relative;

  h2 {
    font-size: 1.5em;
  }
  
  > div {
    height: auto;
  }
  
  .form {
    padding: 1em;
    text-align: left;
  }
  
  label {
    font-weight: 600;
  }

  // for the logo
  img {
    max-width: 200px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .spinner-border {
    vertical-align: middle;
    margin-left: 10px;
  }

  #back-to-overview-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  #error-div-login {
    color: red;
  }

}

#dashboard-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 15px;
  color: rgb(55, 55, 55);
  height: 100vh;
  background: url('./background.jpg');
  background-size: cover;
  
  #logo {
    max-width: 250px;
    margin-bottom: 30px;
  }

  h1 {
    font-size: 20pt;
    margin-bottom: 20px;
  }

  thead, tbody tr {
    display: table;
    width: 800px;
    table-layout: fixed;
}

  #dashboard-table tbody {
    display: block;
    width: 850px;
    max-height: 600px;
    overflow-x: auto;
  }


  #dashboard-table-wrapper{
    padding: 20px;
    background-color: white;
    text-align: center;
  }
  #dashboard-table th {
    color: var(--themakleur);  
  }

  #dashboard-table th, #dashboard-table td {
    padding: 10px 20px;
  }

  #dashboard-table td {
    border-bottom: 1px solid rgba(0,0,0,0.1);
    color: rgb(56, 56, 56);
  }

  #dashboard-search > * {
    margin: 5px 10px 20px 10px;
    display: inline-block;
    vertical-align: middle;
  }

  form > * {
    margin: 5px 10px 20px 10px;
    display: inline-block;
    vertical-align: middle;
  }

  #dashboard-search input {
    width: 350px;
  }
}